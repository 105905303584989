<template>
  <div class="pt-4">
    <v-card
      :loading="loading"
      :flat="true"
      :dense="false"
      :outlined="true"
      elevation="4"
      class="card mx-auto"
    >
      <v-card-title class="justify-center justify-sm-start">
        <h1>Log in</h1>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col sm="4" class="d-none d-sm-block">
              <v-img src="@/assets/logo.png"></v-img>
            </v-col>
            <v-col cols="12" sm="8" class="pa-4">
              <form ref="form" @submit.prevent="submit()">
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  label="Email"
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  :autofocus="!rememberEmail"
                ></v-text-field>

                <v-text-field
                  type="password"
                  v-model="password"
                  :error-messages="passwordErrors"
                  label="Password"
                  required
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  @keyup="onPasswordKeyUp($event)"
                  :autofocus="rememberEmail"
                ></v-text-field>

                <v-alert v-if="capsLockIsOn" outlined type="warning" dense>
                  Caps lock is on
                </v-alert>

                <v-row class="align-center mt-2">
                  <v-col cols="12" md="6" class="py-2">
                    <v-checkbox
                      label="Remember Email?"
                      v-model="rememberEmail"     
                      hideDetails
                      class="mt-0 text-left text-md-right"      
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6" class="text-left text-md-right flex-grow-1 pa-2">
                    <v-btn type="submit" color="primary" class="full-width">Sign In</v-btn>
                  </v-col>
                </v-row>
              </form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import authService from "@/services/auth.service.js";
import { mapGetters } from "vuex";

const savedEmailKey = "persist_loginEmail";

export default {
  components: {},
  mixins: [validationMixin],

  validations: {
    email: { required },
    password: { required },
  },

  data: () => ({
    loading: false,
    email: "",
    password: "",
    capsLockIsOn: false,
    rememberEmail: false,
  }),

  computed: {
    ...mapGetters("AuthModule", ["isAuthenticated"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }

      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }

      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      try {
        const loginModel = {
          email: this.email,
          password: this.password,
          rememberMe: false,
        };

        await authService.login(loginModel);
        if (this.rememberEmail) {
          localStorage.setItem(savedEmailKey, this.email);
        } else {
          localStorage.removeItem(savedEmailKey);
        }

        this.$router.replace({ name: "Home" });
      } finally {
        this.loading = false;
      }
    },
    onPasswordKeyUp(event) {
      this.capsLockIsOn = !!event.getModifierState("CapsLock");
    },
    clear() {
      this.$v.$reset();
      this.email = "";
      this.password = "";
    },
  },
  mounted() {
    var savedEmail = localStorage.getItem(savedEmailKey);
    if (savedEmail) {
      this.email = savedEmail;
      this.rememberEmail = true;
    }
  },
  watch: {},
};
</script>

<style scoped>
.card {
  max-width: 800px;
}
</style>
